import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import { TourProvider } from "@reactour/tour"

import { ReactComponent as LoadingPuffIcon } from "./assets/loading_puff.svg"
import Upkeep from "./upkeep"
import Pause from "./pause"
//Temporary pages
import StudioTemp from "@/pages/temp/StudioTemp"
import Preview from "@/pages/temp/Preview"

import * as store from "./store"
import "./i18n/config"
import AppRouter from "./router"
import { tourProviderProps } from "./components/tour"
import ZhangjiangV2Studio from "./pages/zhangjiangV2/studio"
import ZhangjiangV2Preview from "./pages/zhangjiangV2/Preview"
import DajiaInsurance from "./pages/DajiaInsurance"
// import * as Sentry from "@sentry/react"
import ProfileEditor from "@/components/bussiness/ProfileEditor/index"
import { JumpToWeChat } from "./utils"

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: "https://2774c8927efd4a3091c852b8bcd04f9b@sentry-api.tiamat.world/2",
//   })
//   Sentry.captureMessage("pageEnter")
// }

const tempPagesRouter = createBrowserRouter([
  {
    path: "/",
    element: <StudioTemp />,
  },
])

const App = observer(() => {
  // return <Upkeep />

  // 首次进入时携带的query, 记录来源
  store.updateFromQuery(location.search)

  useEffect(() => {
    if (window.location.pathname !== "/shang-hai-film-infinity") {
      JumpToWeChat()
    }
  }, [])
  const u = store.user.get()
  // return <Upkeep />
  if (u.status === "pending") {
    // @ts-ignore
    document.querySelector("#loadingText").style.display = "none"
    return (
      <div className="fixed inset-0 flex text-lg text-center items-center flex-col gap-8 justify-center bg-[#161622] text-white">
        <LoadingPuffIcon />
        正在加载中…… <br />
        <br />
        若等待时间过长，请刷新重试～
      </div>
    )
  } else if (u.status === "upkeep") {
    return <Upkeep />
  } else {
    if (u?.isDaJiaBaoXianUser) {
      return <DajiaInsurance />
    } else if (u?.isZhangjiangV2User) {
      return <ZhangjiangV2Studio />
    } else if (u?.isZhangjiangV2Actor) {
      return <ZhangjiangV2Preview />
    } else if (u?.isZhangjiangUser) {
      return <RouterProvider router={tempPagesRouter}></RouterProvider>
    } else if (u?.isZhangjiangActor) {
      return <Preview></Preview>
    } else {
      return <AppRouter />
    }
  }
})

ReactDOM.createRoot(document.getElementById("app") as HTMLElement).render(
  // <StrictMode>
  <TourProvider {...tourProviderProps}>
    <ProfileEditor />
    <App />
  </TourProvider>
  // </StrictMode>
)
