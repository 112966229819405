import { flow, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import payImgURL from "../assets/subscription_pay_fail.png"
import {
  V1CreateTaskRequest,
  V1TaskStatus,
  V1ReGenerateRequest,
  V1TaskPriority,
  V1TemplateData,
} from "@/generated/api/data-contracts"
import * as store from "@/store"
import { LogicError, ReferencelIamgeDataType } from "@/types"
import { toastError } from "@/common/Toast"
import { ReactComponent as ArrowRightDarkIcon } from "@/assets/arrow_right_dark.svg"
import { isMobileOnly } from "react-device-detect"
import { DEFAULT_MODEL, MODELS, ModelV2, V5_MODELS, getRecordNumberByDomain } from "@/constants"
import { WarnningModal } from "./components/WarningModal"
import {
  apiCreateTaskForGuest,
  apiListBusyTasks,
  apiRegenerateTask,
  updateStudioSettings,
  createTask as createTaskService,
  createV2Task as createV2TaskService,
  guestApiRegenerateTask,
} from "../services/editor"
import { ErrorCode } from "@/i18next"
import { useTranslation } from "react-i18next"
import { RegenOptions } from "@/pages/Works/Card"
import { Modal } from "@arco-design/web-react"
import { ControlNetInStudioPage } from "./components/ControlNet/ControlNet"
import { UploadReferenceImageInStudioPage } from "./components/UploadreferenceImage"
import { useTourHooks } from "@/components/tour"
import studioPagesStore from "@/store/studioPagesStore"
import _ from "lodash"
import TemplateRecommendation from "./components/TemplateRecommendation"
import StudioOptions from "./components/StudioOptions"
import PromptBox from "./components/PromptBox"
import GenerationHistory from "./components/GenerationHistory"
import MobileHeaderMenu from "@/components/layout/temp/MobileHeaderMenu"
import MobileHeaderActions from "./MobileHeaderActions"
import templateStore, { formatTemplatesModel } from "@/store/templateStore"
import MobileContent from "./MobileContent"
import { getNormalPromptByTemplatePromptJSON } from "@/utils/format"
import ActivitySelect from "./components/ActivitySelect"
import activityStore from "@/store/activityStore"
import { getTemplateDetail } from "@/services/home"
import { findModelPattern } from "@/utils"

// keep state in current page
// TODO in browser session(cross tabs)?
let currentTasksLoaded = false
type ReferencelIamgeEvent = { onOpen: () => void; init?: () => void; editor?: () => void }
type ControlNetEvent = { onOpen: (mode?: Array<string> | []) => void }

export default observer(function () {
  const { t } = useTranslation(["translation", "errorMessage", "randomPrompt"])
  const {
    privateGenEditable,
    fastGenEditable,
    isPrivate,
    isHigh,
    model: modelId,
    originalModel,
    runingTasks,
    referencelIamgeData,
    workId,
    autogeneration,
    prompt: studioTextPrompt,
    negativeTextPrompt: studioNegativeTextPrompt,
    ratio,
    referencedImage,
    modelVersion,
    modelPattern,
    referPower,
  } = studioPagesStore.store.get()
  const { onGoingActivities } = activityStore.store.get()
  const {
    id: userId,
    subscribedProduct, //是否订阅
    fastGenerationRemainingSeconds, // 快速余额
    // fastGenerationTotalSeconds,
    freeGenerationRemainingCount = 0, // 免费次数
    freeGenerationTotalCount,
    oneTimeSupplementRemainingSeconds, // 加量包
    // oneTimeSupplementTotalSeconds,
    visitorCreated,
  } = store.user.get()
  const navigate = useNavigate()
  const { openTour } = useTourHooks({ key: "tour-studio-tag-card" })
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const activityID = searchParams.get("activityID") || ""
  const isTIMv5 = modelVersion === "TIMv5"

  const referencelIamgeEventRef = useRef({} as ReferencelIamgeEvent)
  const controlNetEventRef = useRef({} as ControlNetEvent)

  const setIsHigh = (isHigh: boolean) => studioPagesStore.setStore({ isHigh })
  const setIsPrivate = (isPrivate: boolean) => studioPagesStore.setStore({ isPrivate })

  // 由于性能问题，prompt输入框只在离开页面时才设置为studio内容
  const [textPrompt, setTextPrompt] = useState<string>(studioTextPrompt)

  const disabledCreateButton =
    !textPrompt || runingTasks.some((t) => t.status !== "success" && t.status !== "failed")
  const [negativeTextPrompt, setNegativeTextPrompt] = useState<string>(studioNegativeTextPrompt)

  const [activityUID, setActivityUID] = useState<string>("")
  const privacyStatusDisabled = !privateGenEditable || !!activityUID
  const privacyDisabledMessage = activityUID ? "参与活动作品必须公开" : ""

  const [balanceTip, setBalanceTip] = useState<0 | 1 | 2>(0) // 0 不展示 1 没有余额 2 余额小于3
  // 模型
  const [model, setModel] = useState<ModelV2>(() => {
    return modelId ? MODELS.find((m) => m.id === modelId) || DEFAULT_MODEL : DEFAULT_MODEL
  })
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showRegisterModel, setShowRegisterModel] = useState(false)
  // Template States
  const [templateId, setTemplateId] = useState<string | undefined>(undefined)
  const [disabledCreate, setDisabledCreate] = useState(false)

  useEffect(() => {
    templateStore.fetchTemplates()
  }, [])

  useEffect(() => {
    handleActivityIdChangeFromQuery()
  }, [activityID])

  useEffect(() => {
    if (!activityUID) return

    if (isPrivate) {
      handlePrivacyChange(false)
    }
  }, [activityUID])

  // 选择模型
  useEffect(() => {
    if (modelId) {
      const model = MODELS.find((m) => m.id === modelId)
      model && setModel(model)
    }
  }, [modelId])

  // 重绘
  useEffect(() => {
    if (workId) {
      runInAction(() => {
        // 游客创作 - 重绘
        if (!userId) {
          if (!visitorCreated) {
            GuestRegenerateTask({
              workId,
            })
          }
        } else {
          // 用户重绘
          regenerateTask({
            workId,
          })
        }
      })
      studioPagesStore.init(({ taskHistory, runingTasks }) => ({ taskHistory, runingTasks }))
    }
  }, [workId])

  // 自动生成
  useEffect(() => {
    if (autogeneration) {
      studioPagesStore.setStore({
        autogeneration: false,
      })

      const params = {
        model: modelId,
        textPrompt: studioTextPrompt,
        referPower: referPower,
        referencedImage: referencedImage,
      } as any
      if (!referPower) {
        delete params.referPower
      }

      if (!referencedImage) {
        delete params.referencedImage
      }
      createTaskBefore({ ...params })
    }
  }, [autogeneration])

  useEffect(() => {
    if (currentTasksLoaded) return
    currentTasksLoaded = true
    studioPagesStore.init(({ referencelIamgeData }) => ({ referencelIamgeData }))
    apiListBusyTasks().then(({ list }) => {
      const [first] = list || []
      first && studioPagesStore.addRuningTask(first)
    })
  }, [])

  useEffect(() => {
    setDisabledCreate(disabledCreateButton)
  }, [disabledCreateButton])

  // 在咒语、模型、比例变化时，缓存数据
  useEffect(() => {
    studioPagesStore.setStore({
      prompt: textPrompt,
      ratio,
      negativeTextPrompt,
      referencelIamgeData,
    })
  }, [textPrompt, negativeTextPrompt])

  useEffect(() => {
    if (model?.id) {
      studioPagesStore.setStore({
        model: model.id,
      })
    }
  }, [model])

  // 生成成功
  useEffect(() => {
    if (V1TaskStatus.TaskStatusSuccess === runingTasks?.[0]?.status) {
      openTour()
      store.refreshUser()
      studioPagesStore.updateSettings()
      // 手动存一下
      studioPagesStore.setStore({
        prompt: textPrompt,
        negativeTextPrompt: negativeTextPrompt,
      })
    }
  }, [runingTasks?.[0]?.status])

  // Get url params from url
  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const checkOtherTemplateParams = (template: V1TemplateData | undefined) => {
      // Set model
      const isV5 = V5_MODELS.includes(template?.model || DEFAULT_MODEL.id)
      const templateReplcaeModel = formatTemplatesModel([template || {}])?.[0]
      studioPagesStore.setStore({
        model: String(templateReplcaeModel?.model) || DEFAULT_MODEL.id,
        originalModel: String(template?.model) || DEFAULT_MODEL.id,
        modelVersion: isV5 ? "TIMv5" : "TIMv4",
      })

      const newModelPattern = { ...modelPattern }

      newModelPattern.art = template?.model === "auto-tianime-art"
      newModelPattern.portrait = template?.model === "auto-tiality-portrait"

      studioPagesStore.setStore({
        modelPattern: newModelPattern,
      })

      // Get referenceImage from url
      const referenceImage = query.get("referenceImage")

      if (referenceImage) {
        setReferencelIamgeData({
          type: "referencelImage",
          preViewUrl: referenceImage,
          payload: {
            referencedImage: referenceImage,
            referPower: Math.round(0.5 * 100) || 0,
          },
        })
      } else {
        setReferencelIamgeData(undefined)
      }

      // Get aspectRatio from url
      const aspectRatio = template?.aspectRatio
      if (aspectRatio) {
        studioPagesStore.setStore({
          ratio: aspectRatio,
        })
      } else {
        studioPagesStore.setStore({
          ratio: "1:1",
        })
      }

      // ControlNet
      const controlNet = template?.controlNet
      if (controlNet?.units?.[0]) {
        setReferencelIamgeData({
          type: "controlNet",
          preViewUrl: "" || "",
          payload: {
            controlNet: controlNet,
          },
        })
      }
    }

    const fetchTemplate = async (templateId: string) => {
      let template = {} as V1TemplateData

      try {
        const { data, success, errorCode } = await getTemplateDetail(templateId)
        if (success && data?.template) {
          template = data?.template
        } else {
          return toastError(t(`errorMessage:${errorCode as ErrorCode}`))
        }
      } catch (e) {
        console.log(e, "error")
      }
      if (template) {
        setTimeout(() => {
          setTemplateId(templateId)
          setTextPrompt(getNormalPromptByTemplatePromptJSON(template.prompt))
          setNegativeTextPrompt(getNormalPromptByTemplatePromptJSON(template.negativePrompt))
          checkOtherTemplateParams(template)
        }, 0)
      }
    }

    // Get templateId from url
    const templateId = query.get("templateId")
    if (templateId) {
      fetchTemplate(templateId)
    } else {
      setTemplateId(undefined)
    }
  }, [location.search])

  const setReferencelIamgeData = (referencelIamgeData?: ReferencelIamgeDataType) => {
    studioPagesStore.setStore({ referencelIamgeData })
  }

  const handleActivityIdChangeFromQuery = async () => {
    await activityStore.getActivityList()
    const { onGoingActivities } = activityStore.store.get()
    const idIsValide = onGoingActivities.some((item) => item.uid === activityID)
    setActivityUID(idIsValide ? activityID : "")
  }
  const createTask = flow(function* (query: Partial<V1CreateTaskRequest> = {}) {
    // setDisabledCreate(true)
    let fetchIsTIMv5 = isTIMv5
    // 锁定本次任务的生成优先级，这个值只有在创作这里才会改动，用于生成过程中的展示
    studioPagesStore.setStore({
      currentTaskIsHigh: isHigh,
    })

    const params: V1CreateTaskRequest = {
      model: originalModel ? originalModel : model.id,
      negativeTextPrompt: negativeTextPrompt,
      ratio: ratio,
      textPrompt: textPrompt,
      isPrivate: isPrivate,
      activityUID,
      priority: isHigh ? V1TaskPriority.TaskPriorityHigh : V1TaskPriority.TaskPriorityLow,
      ...referencelIamgeData?.payload,
      ...query,
    }

    if (!params?.controlNet) delete params?.controlNet

    if (templateId) params.templateID = Number(templateId)

    if (isTIMv5 && _.isEmpty(query)) {
      const newModel = findModelPattern(modelId, modelPattern)
      params.model = newModel
    }

    const TIMv5Model = [
      "auto-tianime-default",
      "auto-tiality-default",
      "auto-tianime-art",
      "auto-tiality-portrait",
    ]

    fetchIsTIMv5 = TIMv5Model.includes(params?.model || "")
    return toastError("已停止服务")

    // try {
    //   const { id } = fetchIsTIMv5
    //     ? yield createV2TaskService(params)
    //     : yield createTaskService(params)
    //   store.refreshUser()
    //   studioPagesStore.addRuningTask({
    //     id,
    //     createdAt: `${new Date()}`,
    //     status: V1TaskStatus.TaskStatusRunning,
    //     ratio: "",
    //     textPrompt: "",
    //   })
    // } catch (e) {
    //   if (runingTasks?.some((t) => t.status !== "success" && t.status !== "failed")) {
    //     setDisabledCreate(true) // 有创作中的，没法继续再创作
    //   } else {
    //     setDisabledCreate(false)
    //   }

    //   if (e instanceof LogicError) {
    //     if (e.code == "12008") {
    //       setShowWarningModal(true)
    //     }
    //     toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
    //   } else {
    //     toastError(e instanceof Error ? e.message : "unknown error")
    //   }
    // }
  })

  const guestCreateTask = flow(function* (query: Partial<V1CreateTaskRequest> = {}) {
    setDisabledCreate(true)
    const params: V1CreateTaskRequest = {
      model: model.id,
      negativeTextPrompt: negativeTextPrompt,
      ratio: ratio,
      textPrompt: textPrompt,
      isPrivate: isPrivate,
      priority: V1TaskPriority.TaskPriorityLow, // 访客默认低优先级
      activityUID,
      ...referencelIamgeData?.payload,
      ...query,
    }
    if (templateId) params.templateID = Number(templateId)

    try {
      const { id } = yield apiCreateTaskForGuest(params)
      store.refreshUser()
      studioPagesStore.addRuningTask({
        id,
        createdAt: `${new Date()}`,
        status: V1TaskStatus.TaskStatusRunning,
        ratio: "",
        textPrompt: "",
      })
    } catch (e) {
      setDisabledCreate(false)

      if (e instanceof LogicError) {
        if (e.code == "12008") {
          setShowWarningModal(true)
        }
        toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
      } else {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }
  })

  const GuestRegenerateTask = flow(function* (query: V1ReGenerateRequest) {
    // 提示错误由后端处理

    setDisabledCreate(true)

    try {
      const { id } = yield guestApiRegenerateTask(query)
      store.refreshUser()
      studioPagesStore.addRuningTask({
        id,
        createdAt: `${new Date()}`,
        status: V1TaskStatus.TaskStatusRunning,
        ratio: "",
        textPrompt: "",
      })
    } catch (e) {
      setDisabledCreate(false)

      if (e instanceof LogicError) {
        if (e.code == "12008") {
          setShowWarningModal(true)
        }
        toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
      } else {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }
  })

  const regenerateTask = flow(function* (query: V1ReGenerateRequest) {
    // 提示错误由后端处理

    setDisabledCreate(true)

    try {
      const { id } = yield apiRegenerateTask(query)
      store.refreshUser()
      studioPagesStore.addRuningTask({
        id,
        createdAt: `${new Date()}`,
        status: V1TaskStatus.TaskStatusRunning,
        ratio: "",
        textPrompt: "",
      })
    } catch (e) {
      setDisabledCreate(false)

      if (e instanceof LogicError) {
        if (e.code == "12008") {
          setShowWarningModal(true)
        }
        toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
      } else {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }
  })

  // 节流防止狂点
  const createTaskBefore = _.throttle(
    (query: Partial<V1CreateTaskRequest> = {}) => {
      setDisabledCreate(true) // 最快速禁用点击
      if (!userId) {
        if (visitorCreated) {
          setDisabledCreate(false) // 恢复可点击
          return setShowRegisterModel(true)
        }
        // guestCreateTask()
        return setShowRegisterModel(true)
      }

      if (activityUID && isPrivate) {
        query = {
          ...query,
          isPrivate: false,
        }
      }

      createTask(query)
    },
    1000,
    { leading: true, trailing: false }
  )

  // // 免费次数耗尽
  // if (!subscribedProduct && freeGenerationRemainingCount === 0) {
  //   setBalanceTip(1)
  //   return
  // }

  const handleRandomPrompt = () => {
    const len = Number(t("randomPrompt:length"))
    const random = Math.floor(Math.random() * (len - 1))
    // @ts-ignore
    const str: string = t(`randomPrompt:prompt${random}`)
    setTextPrompt(str)
  }

  // 从子组件获得新增的咒语
  const addTagsPrompt = (task: any): void => {
    // setTextPrompt(textPrompt + "，" + additionalPrompt)
    createTaskBefore({
      model: task.model,
      negativeTextPrompt: task.negativeTextPrompt,
      ratio: task.ratio,
      textPrompt: task.textPrompt,
      isPrivate: task.isPrivate,
      activityUID: task.activityUID,
      priority: task.priority,
      controlNet: task.controlNet,
      referencedImage: task.referencedImage,
      referPower: task.referPower,
    })
  }
  // 图像会说话中获取咒语
  const addImageSpeakPrompt = (additionalPrompt: string) => {
    setTextPrompt(additionalPrompt)
    createTaskBefore({
      textPrompt: additionalPrompt,
    })
  }

  const onRegen = ({ textPrompt, negativeTextPrompt, model, ratio }: RegenOptions) => {
    if (disabledCreate) {
      return toastError("当前有任务正在进行中，请稍后再试～")
    }
    setModel(model ? MODELS.find((m) => m.id === model) || DEFAULT_MODEL : DEFAULT_MODEL)
    studioPagesStore.setStore({
      prompt: textPrompt,
      negativeTextPrompt,
      ratio,
    })

    setTimeout(() => {
      createTaskBefore()
    }, 0)
  }

  // const changeSettings = (type: "isHigh" | "isPrivate", value: boolean) => {
  const changeSettings = async (type: "isHigh" | "isPrivate", value: boolean) => {
    try {
      const params: any = {}
      if (type == "isHigh") {
        params.enableFastGen = value
      } else if (type == "isPrivate") {
        params.enablePrivateGen = value
      }
      await updateStudioSettings(params)
    } catch (e) {
      if (e instanceof LogicError) {
        if (e.code == "12008") {
          setShowWarningModal(true)
        }
        toastError(t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"))
      } else {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }
  }

  const onCancel = () => {
    setShowRegisterModel(false)
  }

  const handlePrivacyChange = (isPrivateParam?: boolean) => {
    const newStatus = typeof isPrivateParam === "boolean" ? isPrivateParam : !isPrivate

    setIsPrivate(newStatus)
    changeSettings("isPrivate", newStatus)
  }

  const handlePatternChange = () => {
    const newModelPattern = { ...modelPattern }

    if (modelId.includes("auto-tianime")) {
      newModelPattern.art = !newModelPattern.art
    } else {
      newModelPattern.portrait = !newModelPattern.portrait
    }
    studioPagesStore.setStore({
      modelPattern: newModelPattern,
    })
  }

  const handleSpeedClick = () => {
    setIsHigh(!isHigh)
    changeSettings("isHigh", !isHigh)
  }

  const handleUploadReferenceImage = (isEditor?: boolean) => {
    if (isEditor) {
      referencelIamgeEventRef.current.editor?.()
    } else {
      referencelIamgeEventRef.current.init?.()
    }
  }

  const removeTemplate = () => {
    setTemplateId(undefined)
    setTextPrompt("")
    studioPagesStore.setStore({
      originalModel: "",
    })
    const params = new URLSearchParams(location.search)
    params.delete("templateId")
    const url = `${location.pathname}?${params.toString()}`
    navigate(url)
  }

  return (
    <div>
      {/* Register Modal */}
      <Modal
        onCancel={onCancel}
        visible={showRegisterModel}
        footer={null}
        title={null}
        modalRender={() => {
          return (
            <div className=" arco-modal bg-white dark:bg-black px-4 py-4 rounded">
              <div className="py-6 text-center">
                <div className=" text-sm">注册获得更多生成次数</div>
                <div className=" text-sm">快去注册，领取更多免费生成次数</div>
                <Link to="/login/?status=register">
                  <button className="primary_btn w-[200px] h-[40px] my-6 ">立即注册</button>
                </Link>
              </div>
            </div>
          )
        }}
      ></Modal>

      {/* Balance Tip Modal */}
      <Modal
        visible={balanceTip !== 0}
        footer={null}
        title={null}
        onCancel={() => {
          if (balanceTip === 2) {
            createTask()
          }
          setBalanceTip(0)
        }}
      >
        <div className="w-[30rem] text-center">
          <img className="w-[9rem] mx-auto" src={payImgURL} alt="" />
          {balanceTip === 1 ? (
            <div className=" text-sm">当前生成余额不足，请前往订阅中心进行订阅升级～</div>
          ) : (
            <div className=" text-sm">生成余额即将耗尽，快去订阅中心进行订阅升级吧～</div>
          )}
          <Link to="/subscription">
            <button className="primary_btn w-[200px] h-[40px] my-6 ">立即前往</button>
          </Link>
        </div>
      </Modal>

      {/* Control Net Modal */}
      <ControlNetInStudioPage
        onChange={(v) => {
          setReferencelIamgeData(v)
        }}
        event={controlNetEventRef}
      />

      {/* Reference Image Modal */}
      <UploadReferenceImageInStudioPage
        event={referencelIamgeEventRef}
        tempUrl={referencedImage}
        onChange={(v) => {
          setReferencelIamgeData(v)
        }}
      />

      {/* Warning Modal */}
      {showWarningModal ? <WarnningModal onClose={() => setShowWarningModal(false)} /> : null}

      {/* Mobile Layout */}
      {isMobileOnly && (
        <div className="fixed top-0 left-0 bottom-0 w-full flex flex-col">
          <MobileHeaderMenu
            extra={
              <MobileHeaderActions
                isPrivate={isPrivate}
                onPrivacyClick={handlePrivacyChange}
                privacyDisabled={privacyStatusDisabled}
                privaceDisabledMessage={privacyDisabledMessage}
                isFast={isHigh}
                onControlNet={(mode = []) => {
                  let modeList
                  // 初始状态打开弹窗是字符串mode，再次编辑是数组，需要将二者都处理为数组
                  typeof mode === "string" ? (modeList = [mode]) : (modeList = mode)
                  controlNetEventRef.current.onOpen(modeList)
                }}
                onSpeedClick={handleSpeedClick}
                speedDisabled={!fastGenEditable}
                onUploadReferenceImage={handleUploadReferenceImage}
                onPromptGenerated={addImageSpeakPrompt}
                negativePrompt={negativeTextPrompt}
                onNegativePromptChange={(val) => setNegativeTextPrompt(val)}
                templateId={templateId}
              />
            }
          />
          {onGoingActivities?.length ? (
            <ActivitySelect
              handleChecked={(activityuid) => {
                setActivityUID(activityuid)
              }}
              activtiyId={activityUID}
            />
          ) : (
            ""
          )}

          <div className="flex-1 absolute top-16 left-0 bottom-0 w-full">
            <MobileContent
              onRandomPromptClick={handleRandomPrompt}
              onPromptChange={(val) => setTextPrompt(val)}
              prompt={textPrompt}
              onGenerateClick={createTaskBefore}
              historyComponent={
                <GenerationHistory
                  canCreate={!disabledCreate}
                  addTagsPrompt={addTagsPrompt}
                  onRegen={onRegen}
                />
              }
              templateId={templateId}
              onTemplateRemoveClick={removeTemplate}
            />
          </div>
        </div>
      )}

      {/* Desktop Layout */}
      {!isMobileOnly && (
        <div
          className="mt-0 max-w-[1920px] mx-auto px-3 sm:px-6 md:px-0 flex overflow-y-auto"
          style={{
            height: "calc(100vh - 64px)",
          }}
        >
          <div className="block md:flex pt-2 md:pt-0 md:flex-row w-full">
            {/* Template Recommendation for Mobile */}
            {isMobileOnly && <TemplateRecommendation />}

            {/* 参数设置 */}
            <StudioOptions
              onPromptGenerated={addImageSpeakPrompt}
              onReferenceImageUpload={handleUploadReferenceImage}
              onControlNetUpload={(mode) => {
                // controlNetEventRef.current.onOpen(mode)
                let modeList
                // 初始状态打开弹窗是字符串mode，再次编辑是数组，需要将二者都处理为数组
                typeof mode === "string" ? (modeList = [mode]) : (modeList = mode)
                controlNetEventRef.current.onOpen(modeList)
              }}
            />

            {/* 图片生成 */}
            <div className="flex-initial md:flex-1 md:mt-0 mt-5 bg-[#1c1c28] md:min-w-[440px] flex flex-col overflow-y-auto scrollbar-hidden">
              <PromptBox
                prompt={textPrompt}
                negativePrompt={negativeTextPrompt}
                onPromptChange={(val) => setTextPrompt(val)}
                onActivityChange={(val) => {
                  setActivityUID(val)
                }}
                activityId={activityUID}
                onNegativePromptChange={(val) => setNegativeTextPrompt(val)}
                onRandomPromptClick={handleRandomPrompt}
                privacyDisabled={privacyStatusDisabled}
                privacyDisabledMessage={privacyDisabledMessage}
                isPrivate={isPrivate}
                onPrivacyChange={handlePrivacyChange}
                onPatternChange={handlePatternChange}
                isFast={isHigh}
                speedDisabled={!fastGenEditable}
                onSpeedChange={handleSpeedClick}
                generateButtonDisabled={disabledCreate}
                onGenerateClick={createTaskBefore}
                templateId={templateId}
                removeTemplate={removeTemplate}
              />

              {/* Divider */}
              <div className="w-full h-[1px] bg-white bg-opacity-5 my-[10px] shrink-0"></div>

              {/* History */}
              <GenerationHistory
                canCreate={!disabledCreate}
                addTagsPrompt={addTagsPrompt}
                onRegen={onRegen}
              />

              {/* Beian */}
              <div className="flex-col md:flex-row mt-auto w-full flex justify-center text-xs py-3 items-center cursor-pointer">
                <a href="https://beian.miit.gov.cn/#/Integrated/index">
                  {getRecordNumberByDomain()}
                </a>
                <a
                  className="mt-1 md:mt-0 flex justify-center items-center"
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
                >
                  <img className="pl-2" src="/assets/beian.png" />
                  <p className="pl-2">沪公网安备 31011502020083号</p>
                </a>
              </div>
            </div>

            {/* Template Recommendation for Desktop */}
            {!isMobileOnly && (
              <TemplateRecommendation model={modelId} modelPattern={modelPattern} />
            )}
          </div>
        </div>
      )}
    </div>
  )
})
